import React from 'react';

const GoogleMap = ({ map, address }) => (
  <div
    className='google-map'
    onClick={() => window.open(address, '_blank')}
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/${map})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      cursor: 'pointer',
    }}
  />
);

export default GoogleMap;
