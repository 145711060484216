import { BLACK } from '../utils/consts';

import { FaRegEnvelope, FaFacebook, FaInstagramSquare } from 'react-icons/fa';

const ICON_W = '50px';

const ICONS_STYLE = {
  fontSize: ICON_W,
  width: ICON_W,
  color: BLACK,
  cursor: 'pointer',
};

const getIcon = (child, url) => (
  <a
    target='_blank'
    rel='noreferrer'
    href={url}
    style={{ marginLeft: '15px', marginRight: '15px' }}
  >
    {child}
  </a>
);

const Footer = ({ onClick }) => (
  <div
    style={{
      userSelect: 'none',
      height: '120px',
      backgroundColor: 'white',
      fontSize: '.8em',
    }}
  >
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px',
      }}
    >
      {getIcon(
        <FaRegEnvelope style={{ ...ICONS_STYLE }} />,
        'mailto:audreymarin05@gmail.com?subject=informations&body=Bonjour, je souhaiterais obtenir des informations.'
      )}
      {getIcon(
        <FaFacebook style={{ ...ICONS_STYLE }} />,
        'https://www.facebook.com/audrey.marin.58'
      )}
      {getIcon(
        <FaInstagramSquare style={{ ...ICONS_STYLE }} />,
        'https://www.instagram.com/audrey.matsyoga/?fbclid=IwAR29ca40mN8YzPgVlaiJCx96A9jgfa4Z5m3_Kxmb4M9PT_ciHrBgI2KXmfc'
      )}
    </div>
    <br />
    <br />
    <p
      style={{ float: 'left', paddingLeft: '12px' }}
    >{`copyright© ${new Date().getFullYear()}`}</p>
    <p
      style={{ float: 'right', paddingRight: '12px', cursor: 'pointer' }}
      onClick={onClick}
    >
      mentions légales
    </p>
  </div>
);

export default Footer;
