import { useHistory, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import Bullets from '../components/Bullets';
import Section from '../components/Section';

import { DeviceContext, MENU_HOME } from '../utils/consts';
import { scrollIntoView } from '../utils/helpers';
import Footer from '../components/Footer';

const Page = ({ data, showLegalMentions }) => {
  const [currSectionIndex, setCurrSectionIndex] = useState(0);
  const [pageData, setPageData] = useState({});
  const history = useHistory();
  const isMobile = useContext(DeviceContext);
  const { sections = [], primaryColor, secondaryColor } = pageData;
  const { id = MENU_HOME } = useParams();

  useEffect(() => {
    if (id && data.pages) {
      const currPageData = data.pages.find((page) => page.title === id);
      if (currPageData) {
        setPageData(currPageData);
      } else {
        history.push('/'); // go back to Home if id is not valid
      }
    }
  }, [id, data, history]);

  // scroll to the top of the page when changing menu
  useEffect(() => {
    scrollIntoView('section-0', isMobile);
  }, [pageData, isMobile]);

  // scroll to the top of the page when changing menu
  useEffect(() => {
    if (pageData && pageData.title) {
      const title =
        pageData.title.charAt(0).toUpperCase() + pageData.title.slice(1);
      document.title = `Matsyoga - ${title}`;
    }
  }, [pageData]);

  return (
    <div id='container' key={'page'}>
      <div
        id='page'
        style={{
          position: 'relative',
          backgroundColor: 'white',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {sections.map((section, i) => (
          <Section
            key={`section-${i}`}
            section={section}
            index={i}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            setCurrSectionIndex={setCurrSectionIndex}
          />
        ))}
        <Bullets
          sections={sections}
          bgColor={primaryColor}
          currSectionIndex={currSectionIndex}
        />
      </div>
      <Footer onClick={showLegalMentions} />
    </div>
  );
};

export default Page;
