const Cross = ({ onClick }) => (
  <div
    id='cross'
    style={{
      position: 'fixed',
      top: '10px',
      right: '40px',
      color: 'white',
      cursor: 'pointer',
      fontSize: '2em',
    }}
    onClick={onClick}
  >
    ⤬
  </div>
);

export default Cross;
