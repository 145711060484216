export const keepSafe = (idx, nb) => ((idx % nb) + nb) % nb;

export const goToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const shuffleArray = (array) => {
  let currentIndex = array.length;
  let temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const map = (value, start1, stop1, start2, stop2) =>
  start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));

export const positionStyle = (width, height, left, top, right, bottom) => {
  const style = {
    position: 'absolute',
    width,
    height,
  };
  if (bottom || bottom === 0) {
    style.bottom = bottom;
  } else {
    style.top = top;
  }
  if (right || right === 0) {
    style.right = right;
  } else {
    style.left = left;
  }
  return style;
};

export const bgStyle = (
  url,
  backgroundSize = 'contain',
  backgroundPosition = 'center center'
) => ({
  backgroundSize,
  backgroundPosition,
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${url})`,
});

export const borderStyle = (color) => ({
  border: `1px solid ${color}`,
  boxSizing: 'border-box',
});

export const scrollIntoView = (id, isMobile) => {
  const yOffset = isMobile ? -70 : -110;
  const element = document.getElementById(id);
  if (element) {
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};
