import { bgStyle, scrollIntoView } from '../utils/helpers';

import grey from '../assets/grey.png';
import { DeviceContext } from '../utils/consts';
import { useContext } from 'react';

const Bullets = ({ sections, bgColor, currSectionIndex }) => {
  const isMobile = useContext(DeviceContext);
  const GAP = isMobile ? 22 : 20;
  const BULLET_SIZE = isMobile ? 32 : 42;
  const border = isMobile ? '1px solid white' : '3px solid white';
  const dropShadow = isMobile
    ? 'drop-shadow(0px 0px 2px rgba(0,0,0,0.4))'
    : 'drop-shadow(0px 0px 3px rgba(0,0,0,0.2))';

  return (
    <div
      id='bullets-container-1'
      style={{
        width: `${BULLET_SIZE}px`,
      }}
    >
      <div
        id='bullets-container-2'
        style={{
          position: 'fixed',
          width: `${BULLET_SIZE}px`,
          ...bgStyle(
            grey,
            `20% ${(sections.length - 1) * (BULLET_SIZE + GAP)}px`,
            `center ${BULLET_SIZE / 2}px`
          ),
        }}
      >
        {sections.map((section, i) => (
          <div
            key={i}
            id={`bullet-${i}`}
            style={{
              width: `${BULLET_SIZE}px`,
              height: `${BULLET_SIZE}px`,
              backgroundColor: bgColor,
              border: i === currSectionIndex ? border : '',
              boxSizing: 'border-box',
              borderRadius: '50%',
              marginBottom: `${GAP}px`,
              cursor: 'pointer',
              filter: i === currSectionIndex ? dropShadow : '',
              backgroundImage: `url(${process.env.PUBLIC_URL}/icons/${section.icon})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '60%',
              transform: i === currSectionIndex ? 'scale(1.2)' : '',
              transition: 'transform .2s, filter .2s',
            }}
            onClick={() => scrollIntoView(`section-${i}`, isMobile)}
          />
        ))}
      </div>
    </div>
  );
};

export default Bullets;
