import { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import TitleAndGridAndText from './TitleAndGridAndText';

const YoutubeVideo = ({ videoId, title, text, primaryColor }) => {
  const container = useRef(null);

  const [videoWH, setVideoWH] = useState({ width: 1, height: 1 });

  useEffect(() => {
    if (container && container.current) {
      const width = 0.7 * container.current.getBoundingClientRect().width;
      const height = (width / 640) * 390; // original youtube video size
      setVideoWH({ width, height });
    }
  }, [container]);

  const opts = {
    width: videoWH.width,
    height: videoWH.height,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: false,
    },
  };

  return (
    <>
      <TitleAndGridAndText
        title={title}
        text={text}
        primaryColor={primaryColor}
      />
      <div className='component-container' ref={container}>
        {videoWH.width > 1 && <YouTube videoId={videoId} opts={opts} />}
      </div>
    </>
  );
};

export default YoutubeVideo;
