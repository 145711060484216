import {
  BLACK,
  GREY,
  MENUS,
  MENU_HOME,
  TRANSITION_DURATION,
} from '../utils/consts';

import logo from '../assets/logo_final.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const BURGER_WIDTH = 36;
const LINE_HEIGHT = 6;
const LINE_GAP = 6;
const TRANSITION = `${TRANSITION_DURATION / 1000}s`;

const HeaderMobile = ({ primaryColor, id, delayAndGo, mobileMenuOpacity }) => {
  const [showMenu, setShowMenu] = useState(false);

  const getStyle = () => ({
    width: '100%',
    height: `${LINE_HEIGHT}px`,
    backgroundColor: primaryColor,
    marginBottom: `${LINE_GAP}px`,
    borderRadius: `${LINE_HEIGHT / 2}px`,
  });

  return (
    <>
      <div id='header-2'>
        <div
          style={{
            width: '30%',
            height: '100%',
          }}
        >
          <Link to={`/`}>
            <div
              id='header-logo-1'
              style={{
                width: '100%',
                height: '100%',
                marginLeft: '10px',
                backgroundSize: 'auto 70%',
                backgroundPosition: 'left center',
                backgroundImage: `url(${logo})`,
                backgroundRepeat: 'no-repeat',
              }}
            />
          </Link>
        </div>
        <h3
          id='menu-title'
          style={{
            width: '50%',
            marginTop: '32px',
            color: primaryColor,
            textTransform: 'capitalize',
            textAlign: 'right',
            marginRight: '10px',
            opacity: mobileMenuOpacity,
            transition: `opacity ${TRANSITION}`,
          }}
        >
          {id || MENU_HOME}
        </h3>
        <div
          style={{
            width: '20%',
            height: '100%',
          }}
        >
          <div
            className='no-select'
            style={{
              width: `${BURGER_WIDTH}px`,
              height: `${BURGER_WIDTH}px`,
              marginRight: '10px',
              marginTop: '20px',
              userSelect: 'none',
              cursor: 'pointer',
            }}
            onClick={() => setShowMenu(!showMenu)}
          >
            <div
              style={{
                ...getStyle(),
                transition: `transform .2s, background-color ${TRANSITION}`,
                transform: showMenu
                  ? 'rotate(-45deg) translate(-10px, 7px)'
                  : '',
              }}
            />
            <div
              style={{
                ...getStyle(),
                opacity: showMenu ? 0 : 1,
                transition: `opacity .2s, background-color ${TRANSITION}`,
              }}
            />
            <div
              style={{
                ...getStyle(),
                transition: `transform .2s, background-color ${TRANSITION}`,
                transform: showMenu
                  ? 'rotate(45deg) translate(-10px, -7px)'
                  : '',
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          transition: 'max-height .3s',
          backgroundColor: 'white',
          maxHeight: showMenu ? '200px' : '0px',
          overflow: 'hidden',
          boxShadow: '0 5px 5px -6px #6e6e6e',
        }}
      >
        {MENUS.map((menu, i) => (
          <Link
            to={`/${menu.url}`}
            className='no-select'
            key={menu.name}
            style={{ textDecoration: 'none' }}
            onClick={(e) => {
              delayAndGo(e, `/${menu.url}`);
              setTimeout(() => {
                setShowMenu(false);
              }, TRANSITION_DURATION);
            }}
          >
            <h3
              style={{
                color: menu.url === id ? primaryColor : BLACK,
                fontSize: '1em',
                textAlign: 'center',
                lineHeight: '3em',
                marginTop: 0,
                marginBottom: i === MENUS.length - 1 ? '0' : '2px',
                backgroundColor: GREY,
                textTransform: 'capitalize',
                transition: `color ${TRANSITION}`,
              }}
            >
              {menu.name}
            </h3>
          </Link>
        ))}
      </div>
    </>
  );
};

export default HeaderMobile;
