import React from 'react';

import { bgStyle, keepSafe } from '../utils/helpers';
import Carousel from './Carousel';

import curve from '../assets/curve.png';
import Puces from './Puces';
import FocusedImage from './FocusedImage';
import { withRouter } from 'react-router-dom';

const SPEED_DECELERATION = 0.95;
const DEFAULT_MIN_SPEED = 0.05;
const DEFAULT_IMAGE_WIDTH = '100%';
const DEFAULT_IMAGE_HEIGHT = '100%';
const DEFAULT_GAP = 0;

const ANIM_DURATION = 33;

const cubicOut = (t) => --t * t * t + 1;

class IndexManager extends React.Component {
  swipeDone = false;
  prevDelta = 0;
  touchStarted = false; // detect if the user is touching
  raf = undefined; // requestAnimationFrame reference
  didMount = false;

  state = {
    currIdx: 0,
    targetIdx: 0,
    speed: 0,
    magnet: false,
    mouseStartXorY: 0,
    mouseLastXorY0: 0,
    mouseLastXorY1: 0,
    hasInteracted: false,
    actualElementWidth: 0,
    focusedImage: undefined,
  };

  componentDidMount() {
    this.didMount = true;
    this.initStateIM(true);
    window.addEventListener('resize', () => this.initStateIM(false));
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.raf);
    this.didMount = false;
    window.removeEventListener('resize', () => this.initStateIM(false));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageWidth !== this.props.imageWidth) {
      this.initStateIM();
    }
  }

  initStateIM = (firstTime) => {
    if (!this.didMount) return;
    const {
      startIdx = 0,
      imageWidth = DEFAULT_IMAGE_WIDTH,
      imageHeight = DEFAULT_IMAGE_HEIGHT,
      gap = DEFAULT_GAP,
    } = this.props;

    let actualFrameWidth = 0;
    let actualFrameHeight = 0;

    if (this.wrapper) {
      actualFrameWidth = this.wrapper.clientWidth;
      actualFrameHeight = this.wrapper.clientHeight;
    }

    const actualElementWidth = Math.round(
      (parseFloat(imageWidth) / 100) * actualFrameWidth
    );
    const actualElementHeight = Math.round(
      (parseFloat(imageHeight) / 100) * actualFrameHeight
    );

    const boundXleft = Math.round((actualFrameWidth - actualElementWidth) / 2);

    const actualGap = gap.toString().includes('%')
      ? Math.round((parseFloat(gap) / 100) * actualFrameWidth)
      : gap;

    if (firstTime) {
      this.targetIdx = startIdx || 0;
      this.speed = 0;
      this.magnet = false;
      this.mouseStartXorY = 0;
      this.mouseLastXorY0 = 0;
      this.mouseLastXorY1 = 0;
      this.setState({
        currIdx: startIdx,
        targetIdx: startIdx,
      });
    }

    this.setState({
      actualElementWidth,
      actualElementHeight,
      actualFrameWidth,
      actualFrameHeight,
      actualGap,
      boundXleft,
    });
  };

  onTouchStart = (event) => {
    if (this.props.images && this.props.images.length === 1) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.swipeDone = false;
    this.touchStarted = true;
    const { dragEnabled } = this.props;
    const currXorY = this.getCurrentXorY(event);
    if (dragEnabled === false) {
      this.prevMousePos = currXorY;
    } else {
      this.raf = window.cancelAnimationFrame(this.raf);
      this.mouseStartXorY = currXorY;
      this.mouseLastXorY0 = currXorY;
      this.mouseLastXorY1 = currXorY;
      const { currIdx } = this.state;
      this.setState({
        startIdx: currIdx,
      });
    }
  };

  onTouchMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!this.touchStarted || (event.touches && event.touches.length > 1)) {
      return;
    }

    const { dragEnabled = true, isMobile } = this.props;
    const currentXorY = this.getCurrentXorY(event);
    if (dragEnabled === false) {
      this.swipe(event, currentXorY);
      return;
    }

    const { actualElementWidth, actualGap, startIdx } = this.state;
    const actualElementWidthOrHeight = actualElementWidth;
    const { mouseStartXorY } = this;
    this.mouseLastXorY0 = this.mouseLastXorY1;
    this.mouseLastXorY1 = currentXorY;

    this.setState({
      currIdx:
        startIdx +
        (mouseStartXorY - currentXorY) /
          (actualElementWidthOrHeight + actualGap) /
          (isMobile ? 1 : 2),
    });
  };

  onTouchEnd = () => {
    if (!this.touchStarted) {
      return;
    }
    const { dragEnabled } = this.props;
    if (dragEnabled !== false) {
      this.setRelevantSpeed();
    }
    this.touchStarted = false;
  };

  swipe = (event, currentXorY) => {
    event.stopPropagation();
    event.preventDefault();

    const { inputSensitivity = 20 } = this.props;
    if (
      currentXorY < this.prevMousePos - inputSensitivity ||
      currentXorY > this.prevMousePos + inputSensitivity
    ) {
      this.touchStarted = false;
      this.moveCurrIdxBy(
        currentXorY > this.prevMousePos + inputSensitivity ? -1 : 1
      );
    }
  };

  setRelevantSpeed() {
    const { currIdx } = this.state;
    const { minSpeed, images, isPrices } = this.props;
    const { mouseStartXorY, mouseLastXorY0, mouseLastXorY1 } = this;
    let speed = 0;
    if (mouseStartXorY === mouseLastXorY0) {
      // deal with click here
      if (isPrices) {
        this.props.history.push('/informations');
      } else {
        const safeIdx = keepSafe(Math.floor(currIdx), images.length);
        this.setState({ focusedImage: `url(${images[safeIdx]}` });
      }
    } else {
      speed = (mouseLastXorY0 - mouseLastXorY1) / 200;
    }

    if (Math.abs(speed) < (minSpeed || DEFAULT_MIN_SPEED)) {
      this.getToMagnetState(speed, currIdx);
    } else {
      this.speed = speed;
      this.magnet = false;
      this.raf = window.requestAnimationFrame(this.refresh);
    }
  }

  startAnimation = (currIdx, targetIdx) => {
    const newIdx = targetIdx;
    this.magnet = true;
    this.startValue = currIdx;
    this.targetIdx = newIdx;
    this.currentTime = 1 / ANIM_DURATION;
    this.animDuration = Math.abs(currIdx - newIdx) * ANIM_DURATION;
  };

  getToMagnetState = (speed, currIdx) => {
    let targetIdx =
      speed > 0 ? Math.floor(currIdx + 1) : Math.ceil(currIdx - 1);
    if (speed === 0) {
      targetIdx = Math.round(currIdx);
    }
    this.speed = speed;

    this.startAnimation(currIdx, targetIdx);
    this.raf = window.requestAnimationFrame(this.refresh);
  };

  getCurrentXorY(evt) {
    const bounds = evt.target.getBoundingClientRect();
    const eventProperty = 'pageX';
    const boundProperty = 'left';

    return (
      ((evt.touches && evt.touches[0]) ||
        (evt.changedTouches && evt.changedTouches[0]) ||
        evt)[eventProperty] - bounds[boundProperty]
    );
  }

  refresh = () => {
    const {
      minSpeed,
      speedDeceleration,
      setCreativeIdx = () => {},
    } = this.props;
    const { speed, magnet, targetIdx, animDuration, currentTime } = this;
    const { currIdx } = this.state;
    if (magnet) {
      if (currentTime > animDuration) {
        this.magnet = false;
        this.setState({
          currIdx: targetIdx,
        });
        this.prevDelta = 0;
        // console.log('END');
        setCreativeIdx(targetIdx);
        this.raf = undefined;
      } else {
        const newIdx =
          this.startValue +
          cubicOut(currentTime / animDuration) * (targetIdx - this.startValue);
        this.setState({
          currIdx: newIdx,
        });
        this.raf = window.requestAnimationFrame(this.refresh);
      }
      this.currentTime += 1;
    } else if (Math.abs(speed) < (minSpeed || DEFAULT_MIN_SPEED)) {
      this.getToMagnetState(speed, currIdx);
    } else {
      this.speed = speed * (speedDeceleration || SPEED_DECELERATION);
      this.raf = window.requestAnimationFrame(this.refresh);
      this.setState({ currIdx: currIdx + speed });
    }
  };

  ////////// called by the outside world /////////
  moveCurrIdxBy = (delta) => {
    const { targetIdx } = this;
    const { currIdx } = this.state;
    let newIdx = currIdx + delta;

    if (currIdx < targetIdx) {
      newIdx = Math.ceil(currIdx + delta);
    } else {
      newIdx = Math.floor(currIdx + delta);
    }

    this.startAnimation(currIdx, newIdx);
    this.raf = this.raf || window.requestAnimationFrame(this.refresh);
  };

  moveCurrIdxTo = (idx) => {
    const { currIdx } = this.state;
    const { images } = this.props;
    let delta = idx - keepSafe(currIdx, images.length);
    if (Math.abs(delta) > images.length / 2) {
      if (delta < 0) {
        delta += images.length;
      } else {
        delta -= images.length;
      }
    }
    this.moveCurrIdxBy(delta);
  };

  stopEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  ////////////////////////////////////////////////////

  render = () => {
    const {
      images,
      backgroundSize,
      backgroundPosition,
      magnifyFactor = 1,
      puceColor,
      opacityMin = 1,
      imageWidth = DEFAULT_IMAGE_WIDTH,
      isMobile,
      isPrices,
    } = this.props;
    const {
      currIdx,
      actualFrameWidth,
      actualElementWidth,
      actualElementHeight,
      boundXleft,
      actualGap,
      focusedImage,
    } = this.state;

    const height = !isMobile ? 300 : isPrices ? 250 : 150;
    return (
      <div
        ref={(r) => (this.wrapper = r)}
        className='index-manager-container'
        style={{ height: `${height}px` }}
      >
        <Carousel
          currIdx={currIdx}
          images={images}
          backgroundSize={backgroundSize}
          backgroundPosition={backgroundPosition}
          actualFrameWidth={actualFrameWidth}
          actualElementWidth={actualElementWidth}
          actualElementHeight={actualElementHeight}
          magnifyFactor={magnifyFactor}
          boundXleft={boundXleft}
          actualGap={actualGap}
          opacityMin={opacityMin}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
        />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '25%',
            imageWidth,
            ...bgStyle(backgroundSize !== 'contain' ? curve : '', 'cover'),
          }}
        >
          {images.length > 1 && (
            <Puces
              nb={images.length}
              currIdx={currIdx}
              moveCurrIdxTo={this.moveCurrIdxTo}
              color={puceColor}
            />
          )}
        </div>
        {focusedImage && (
          <FocusedImage
            backgroundImage={focusedImage}
            close={() => this.setState({ focusedImage: undefined })}
          />
        )}
      </div>
    );
  };
}

// allows to access the history to navigate to information page
export default withRouter(IndexManager);
