import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

import { MENUS } from '../utils/consts';
import { TRANSITION_DURATION } from '../utils/consts';

const Header = ({ isMobile, setOpacity }) => {
  const [mobileMenuOpacity, setMobileMenuOpacity] = useState(1);
  const { id = '' } = useParams();
  const { primaryColor } = MENUS.find((m) => m.url === id) || {};
  const history = useHistory();

  const delayAndGo = (e, to) => {
    e.preventDefault();
    if (history.location.pathname !== to) {
      setOpacity(0);
      setMobileMenuOpacity(0);
      setTimeout(() => {
        history.push(to);
        setOpacity(1);
        setMobileMenuOpacity(1);
      }, TRANSITION_DURATION);
    }
  };

  const props = { primaryColor, id, setOpacity, delayAndGo, mobileMenuOpacity };
  return (
    <div id='header'>
      {isMobile ? <HeaderMobile {...props} /> : <HeaderDesktop {...props} />}
    </div>
  );
};

export default Header;
