import { Link } from 'react-router-dom';

const SquareButton = ({ text, color, href }) => (
  <div
    className='component-container'
    style={{ display: 'flex', paddingTop: '0px' }}
  >
    <Link
      to={href}
      className='square-button'
      style={{ color, border: `2px solid ${color}` }}
    >
      {text}
    </Link>
  </div>
);

export default SquareButton;
