import { useState } from 'react';
import { BLACK, GREY } from '../utils/consts';

const GridContent = ({ content, backgroundColor, hoverSupported, isDay }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className='grid-content'
      style={{
        border: `1px solid ${GREY}`,
        boxSizing: 'border-box',
        backgroundColor,
        width: '100%',
        height: '100%',
        opacity: hover ? 0.4 : 1,
        transition: 'opacity .2s',
        userSelect: 'none',
        fontSize: '.85em',
        color: isDay ? BLACK : 'white',
      }}
      onMouseEnter={() => setHover(hoverSupported)}
      onMouseLeave={() => setHover(false)}
    >
      {content}
    </div>
  );
};

export default GridContent;
