import { Link } from 'react-router-dom';
import { bgStyle } from '../utils/helpers';

const ProfilePicture = ({ color, profilePicture }) => (
  <Link to={'./informations'}>
    <div
      className='profile-picture'
      style={{
        position: 'relative',
        border: `4px solid ${color}`,
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 99,
        borderRadius: '50%',
        ...bgStyle(
          `${process.env.PUBLIC_URL}/images/${profilePicture}`,
          'cover'
        ),
      }}
    />
  </Link>
);

export default ProfilePicture;
