import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { FaChevronDown } from 'react-icons/fa';

import { scrollIntoView } from '../utils/helpers';

import IndexManager from './IndexManager';
import TitleAndGridAndText from './TitleAndGridAndText';
import GoogleMap from './GoogleMap';
import SquareButton from './SquareButton';
import ProfilePicture from './ProfilePicture';
import { DeviceContext } from '../utils/consts';
import YoutubeVideo from './YoutubeVideo';

const Section = ({
  section,
  index,
  primaryColor,
  secondaryColor,
  setCurrSectionIndex,
}) => {
  const { components = [] } = section;
  const { ref, inView } = useInView({ threshold: 0.3 });

  const isMobile = useContext(DeviceContext);

  useEffect(() => {
    inView && setCurrSectionIndex(index);
  }, [inView, index, setCurrSectionIndex]);

  const getComponent = (component, i) => {
    const { title, text, images, map, address, button, grid, prices, videoId } =
      component;
    const key = `key-${i}`;
    if (videoId) {
      console.log('videoId: ', videoId);
      return (
        <YoutubeVideo
          key={key}
          videoId={videoId}
          title={title}
          text={text}
          primaryColor={primaryColor}
        />
      );
    }
    if (text) {
      return (
        <TitleAndGridAndText
          key={key}
          primaryColor={primaryColor}
          title={title}
          text={text}
          grid={grid}
          isMobile={isMobile}
          secondaryColor={secondaryColor}
        />
      );
    } else if (images) {
      const { profilePicture } = component;
      return (
        <div key={key}>
          <IndexManager
            key={`carousel-${i}`}
            images={images.map(
              (name) => `${process.env.PUBLIC_URL}/images/${name}`
            )}
            imageWidth={images.length === 1 ? '100%' : isMobile ? '50%' : '40%'}
            imageHeight={'100%'}
            gap={'0%'}
            puceColor={secondaryColor}
            startIdx={Math.floor((images.length - 1) / 2)}
            opacityMin={0.5}
            magnifyFactor={1.7}
            isMobile={isMobile}
          />
          {profilePicture && (
            <ProfilePicture
              color={secondaryColor}
              profilePicture={profilePicture}
            />
          )}
        </div>
      );
    } else if (map) {
      return <GoogleMap key={key} map={map} address={address} />;
    } else if (button) {
      const { href } = component;
      return (
        <SquareButton
          key={key}
          color={secondaryColor}
          text={button}
          href={href}
        />
      );
    } else if (prices) {
      return (
        <div key={key}>
          <IndexManager
            images={prices.map(
              (name) => `${process.env.PUBLIC_URL}/prices/${name}`
            )}
            backgroundSize={'contain'}
            puceColor={secondaryColor}
            imageWidth={isMobile ? '70%' : '40%'}
            imageHeight={'80%'}
            magnifyFactor={0.6}
            gap={'3%'}
            startIdx={Math.floor((prices.length - 1) / 2)}
            isMobile={isMobile}
            isPrices={true}
          />
        </div>
      );
    }
  };

  return (
    <div ref={ref} className='section' id={`section-${index}`}>
      {components.map((component, i) => getComponent(component, i))}
      {index === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FaChevronDown
            style={{ fontSize: '55px', color: primaryColor, cursor: 'pointer' }}
            onClick={() => scrollIntoView('section-1', isMobile)}
          />
        </div>
      )}
    </div>
  );
};

export default Section;
