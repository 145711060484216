import { keepSafe, positionStyle } from '../utils/helpers';

const Puces = ({ nb, currIdx, moveCurrIdxTo, color }) => {
  const currPuceIdx = Math.round(keepSafe(currIdx, nb));
  return (
    <div
      id='Puces'
      style={{
        ...positionStyle('100%', '12px', 0, null, null, 0),
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {new Array(nb).fill(0).map((_, i) => (
        <div
          key={i}
          style={{
            width: '12px',
            height: '12px',
            backgroundColor: currPuceIdx === i ? 'unset' : color,
            border: `2px solid ${color}`,
            boxSizing: 'border-box',
            borderRadius: '50%',
            marginLeft: '4px',
            marginRight: '4px',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            moveCurrIdxTo(i);
          }}
        />
      ))}
    </div>
  );
};

export default Puces;
