import { useEffect, useState } from 'react';
import {
  GREY,
  BLUE,
  COURS_STUDIO,
  COURS_WEB,
  COURS_ATELIER,
} from '../utils/consts';
import GridContent from './GridContent';

const DAYS_DESKTOP = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
];
const DAYS_MOBILE = ['L', 'Ma', 'Me', 'J', 'V', 'S', 'D'];

const Grid = ({ grid = [], isMobile, primaryColor, secondaryColor }) => {
  const [maxNbEvents, setMaxNbEvents] = useState(1);

  useEffect(() => {
    setMaxNbEvents(
      grid
        .map((values) => values.length)
        .reduce((max, curr) => Math.max(max, curr))
    );
  }, [grid]);

  const getGridContent = (index) => {
    if (index < 8) {
      return (isMobile ? DAYS_MOBILE : DAYS_DESKTOP)[index];
    }

    let idx = index - 7;
    const j = Math.floor(idx / 7);
    const k = idx % 7;
    if (grid[k] && grid[k][j]) {
      return grid[k][j];
    }
    return '';
  };

  return (
    <>
      <div
        className='price-grid'
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, minmax(0, 1fr))', //'auto auto auto auto auto auto auto auto', //
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: '2px',
          overflow: 'hidden',
          border: `1px solid ${GREY}`,
          width: isMobile ? '140' : '100%',
          marginLeft: isMobile ? '-20%' : '0%',
        }}
      >
        {new Array(7 * (1 + maxNbEvents)).fill(0).map((_, i) => {
          let content = getGridContent(i);
          let backgroundColor = 'white';
          let href, subject, body, day, time;
          if (
            i >= 7 &&
            content &&
            (content.includes(COURS_WEB) ||
              content.includes(COURS_STUDIO) ||
              content.includes(COURS_ATELIER))
          ) {
            day = grid[i % 7][0];
            time = content.split(',')[0];
            if (content.includes(COURS_WEB)) {
              subject = 'cours de yoga en ligne';
              body = `Bonjour, je souhaiterais m'inscrire à un cours de yoga en ligne ${day} à ${time}.`;
              backgroundColor = primaryColor;
            } else if (content.includes(COURS_STUDIO)) {
              subject = 'cours de yoga au studio';
              body = `Bonjour, je souhaiterais m'inscrire à un cours de yoga au studio ${day} à ${time}.`;
              backgroundColor = secondaryColor;
            } else if (content.includes(COURS_ATELIER)) {
              subject = 'atelier de yoga';
              body = `Bonjour, je souhaiterais m'inscrire à un atelier de yoga ${day} à ${time}.`;
              backgroundColor = BLUE;
            }
            href = `mailto:audreymarin05@gmail.com?subject=${subject}&body=${body}`;
            content = content.split(',')[0];
          } else if (i < 7) {
            backgroundColor = GREY;
          }

          return href ? (
            <a key={i} href={href} style={{ textDecoration: 'none' }}>
              <GridContent
                content={content}
                backgroundColor={backgroundColor}
                hoverSupported={true}
                isDay={i < 7}
              />
            </a>
          ) : (
            <GridContent
              key={i}
              content={content}
              backgroundColor={backgroundColor}
              isDay={i < 7}
            />
          );
        })}
      </div>
      <br />
      <div
        style={{
          display: isMobile ? 'block' : 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ color: primaryColor }}>
          <span style={{ fontSize: '2em ' }}>●</span>
          <span style={{ verticalAlign: '4px' }}>{` cours en ligne (1h)`}</span>
        </div>
        <div style={{ color: secondaryColor }}>
          <span style={{ fontSize: '2em ' }}>●</span>
          <span
            style={{ verticalAlign: '4px' }}
          >{` cours au studio (1h15)`}</span>
        </div>
        <div style={{ color: BLUE }}>
          <span style={{ fontSize: '2em ' }}>●</span>
          <span style={{ verticalAlign: '4px' }}>{` atelier (2h)`}</span>
        </div>
      </div>
    </>
  );
};

export default Grid;
