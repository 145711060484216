import Grid from './Grid';

const TitleAndGridAndText = ({
  primaryColor,
  secondaryColor,
  title,
  text,
  grid,
  isMobile,
}) => {
  let finalText = [{ text }];
  if (text.includes('[')) {
    const startIndices = [];
    const endIndices = [];
    for (let i = 0; i < text.length; i++) {
      if (text[i] === '[') {
        startIndices.push(i - startIndices.length);
      } else if (text.substring(i).startsWith(']')) {
        endIndices.push(i - endIndices.length);
      }
    }
    if (startIndices.length === endIndices.length) {
      const textWithoutBrackets = text.split(']').join('');
      finalText = [
        {
          text: textWithoutBrackets.substring(0, startIndices[0]),
        },
      ];
      for (let i = 0; i < startIndices.length; i++) {
        const brackets = textWithoutBrackets.substring(
          startIndices[i] + 1,
          endIndices[i]
        );
        const textUrl = brackets.split(',');
        const t = textUrl[0];
        const u = textUrl[1].split(' ').join('');
        finalText.push({ text: t, url: u });

        if (i < endIndices.length - 1) {
          finalText.push({
            text: textWithoutBrackets.substring(
              endIndices[i],
              startIndices[i + 1]
            ),
          });
        }
      }
      finalText.push({
        text: textWithoutBrackets.substring(endIndices[endIndices.length - 1]),
      });
    }
  }

  return (
    <div className='component-container'>
      <h1 style={{ color: primaryColor }}>{title}</h1>
      {grid && (
        <Grid
          grid={grid}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          isMobile={isMobile}
        />
      )}
      <div id='text-container'>
        <p>
          {finalText.map((t, i) => (
            <a key={`text-${i}`} target='_blank' rel='noreferrer' href={t.url}>
              {t.text}
            </a>
          ))}
        </p>
      </div>
    </div>
  );
};

export default TitleAndGridAndText;
