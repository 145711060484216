import { BLACK, MENUS, TRANSITION_DURATION } from '../utils/consts';

import logo from '../assets/logo_final.png';
import matsyoga from '../assets/Matsyoga_black.png';
import { Link } from 'react-router-dom';

const HeaderDesktop = ({ primaryColor, id, delayAndGo }) => {
  return (
    <div id='header-2'>
      <Link to={`/`} onClick={(e) => delayAndGo(e, `/`)}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            width: '270px',
            height: '100%',
          }}
        >
          <div
            id='header-logo-1'
            style={{
              width: '30%',
              height: '100%',
              marginLeft: '30px',
              backgroundSize: 'auto 70%',
              backgroundPosition: 'left center',
              backgroundImage: `url(${logo})`,
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div
            id='header-logo-2'
            style={{
              width: '36%',
              height: '100%',
              marginLeft: '10px',
              backgroundSize: 'contain',
              backgroundPosition: 'left center',
              backgroundImage: `url(${matsyoga})`,
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
      </Link>

      <div
        style={{
          marginTop: '35px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {MENUS.map((menu) => (
          <Link
            key={menu.name}
            to={`/${menu.url}`}
            onClick={(e) => delayAndGo(e, `/${menu.url}`)}
            style={{ textDecoration: 'none' }}
          >
            <h3
              style={{
                color: menu.url === id ? primaryColor : BLACK,
                fontSize: '1em',
                marginRight: '35px',
                textTransform: 'capitalize',
                transition: `color ${TRANSITION_DURATION / 1000}s`,
              }}
            >
              {menu.name}
            </h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HeaderDesktop;
