import Cross from './Cross';

const FocusedImage = ({ backgroundImage, close }) => (
  <div
    style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      zIndex: 9998,
      backgroundColor: 'rgba(0, 0, 0, .9)',
    }}
  >
    <div
      style={{
        position: 'absolute',
        width: '80%',
        height: '80%',
        left: '10%',
        top: '10%',
        backgroundImage,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    />
    <Cross onClick={close} />
  </div>
);

export default FocusedImage;
