import './App.css';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  DeviceContext,
  MOBILE_THRESHOLD,
  TRANSITION_DURATION,
} from './utils/consts';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Header from './components/Header';
import Page from './pages/Page';
import LegalMentions from './components/LegalMentions';

function App() {
  const [isMobile, setIsMobile] = useState(useContext(DeviceContext));
  const [data, setData] = useState({});
  const ml = useRef(null);
  const page = useRef(null);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/data.json`)
      .then((r) => r.json())
      .then((content) => setData(content));

    const detectMobile = () =>
      setIsMobile(window.innerWidth < MOBILE_THRESHOLD);

    window.addEventListener('resize', detectMobile);
    return () => window.removeEventListener('resize', detectMobile);
  }, []);

  const setOpacity = (opacity) => (page.current.style.opacity = opacity);
  const setMlOpacity = (opacity) => {
    ml.current.style.opacity = opacity;
    ml.current.style.pointerEvents = opacity < 1 ? 'none' : 'auto';
  };

  const WholePage = () => (
    <>
      <Header data={data} isMobile={isMobile} setOpacity={setOpacity} />
      <div
        ref={page}
        style={{ transition: `opacity ${TRANSITION_DURATION / 1000}s` }}
      >
        <Page data={data} showLegalMentions={() => setMlOpacity(1)} />
      </div>
      <div
        ref={ml}
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          opacity: 0,
          zIndex: 9999,
          transition: `opacity ${TRANSITION_DURATION / 1000}s`,
          pointerEvents: 'none',
        }}
      >
        <LegalMentions
          hideLegalMentions={() => setMlOpacity(0)}
          legalMentions={data.legalMentions}
        />
      </div>
    </>
  );

  return (
    <BrowserRouter>
      <DeviceContext.Provider value={isMobile}>
        <Switch>
          <Route exact path='/:id' children={<WholePage />} />
          <Route exact path='/' children={<WholePage />} />
        </Switch>
      </DeviceContext.Provider>
    </BrowserRouter>
  );
}

export default App;
