import { createContext } from 'react';

export const MOBILE_THRESHOLD = 700;
export const DeviceContext = createContext(
  window.innerWidth < MOBILE_THRESHOLD
);

export const GREEN = '#c4d203';
export const ORANGE = '#f46036';
export const BLACK = '#6e6e6e';
export const GREY = '#eeeeee';
export const BLUE = '#36B2EC';

export const MENU_HOME = 'accueil';
export const MENU_YOGA = 'yoga';
export const MENU_INFO = 'informations';
export const MENU_HERB = 'herboristerie';

export const COURS_STUDIO = 'studio';
export const COURS_WEB = 'web';
export const COURS_ATELIER = 'atelier';

export const TRANSITION_DURATION = 300; // in milliseconds

export const MENUS = [
  {
    name: MENU_HOME,
    url: '',
    primaryColor: GREEN,
    secondaryColor: ORANGE,
  },
  {
    name: MENU_YOGA,
    url: MENU_YOGA,
    primaryColor: ORANGE,
    secondaryColor: GREEN,
  },
  {
    name: MENU_HERB,
    url: MENU_HERB,
    primaryColor: GREEN,
    secondaryColor: ORANGE,
  },
  {
    name: MENU_INFO,
    url: MENU_INFO,
    primaryColor: ORANGE,
    secondaryColor: GREEN,
  },
];
