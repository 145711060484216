import Cross from './Cross';

const LegalMentions = ({ hideLegalMentions, legalMentions = {} }) => (
  <>
    <div id='ml-container'>
      <div id='ml-title'>{legalMentions.title}</div>
      <br />
      <br />
      <div id='ml-text'>{legalMentions.text}</div>
    </div>
    <Cross onClick={hideLegalMentions} />
  </>
);

export default LegalMentions;
