import React from 'react';

import { keepSafe, map } from '../utils/helpers';

class Carousel extends React.Component {
  initStateCarousel = () => {
    const { actualElementWidth, actualFrameWidth } = this.state;

    // left side of the centered image
    const boundXleft = Math.round((actualFrameWidth - actualElementWidth) / 2);
    this.setState({ boundXleft });
  };

  getImages() {
    const {
      images,
      magnifyFactor = 0.7,
      backgroundPosition = 'center',
      opacityMin = 0.7,
      actualFrameWidth,
      actualElementWidth,
      actualElementHeight,
      actualGap,
      boundXleft,
      currIdx,
      backgroundSize = 'cover',
    } = this.props;

    const nbImages = images.length;

    const elementWidth = magnifyFactor * actualElementWidth + actualGap;
    let iMin = Math.floor(
      currIdx - boundXleft / (actualElementWidth * magnifyFactor)
    );
    let iMax = Math.ceil(
      currIdx +
        (actualFrameWidth - boundXleft) /
          (Math.min(magnifyFactor, 1) * actualElementWidth)
    );

    let imageDivs = [];
    for (let i = iMin; i < iMax; i++) {
      const safeIdx = keepSafe(i, nbImages);
      const d = Math.min(Math.abs(i - currIdx), 1);
      const scale = map(Math.abs(d), 0, 1, 1, magnifyFactor);
      let x = boundXleft + (i - currIdx) * elementWidth;
      if (i >= currIdx) {
        x += actualElementWidth * map(d, 0, 1, 0, 1 - magnifyFactor);
      }
      imageDivs.push(
        <div
          key={i}
          style={{
            position: 'absolute',
            marginBottom: 'unset',
            backgroundSize,
            backgroundPosition,
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${images[safeIdx]}`,
            backgroundColor: 'white',
            opacity: map(Math.abs(d), 0, 1, 1, opacityMin),
            left: `${x}px`,
            width: `${actualElementWidth * scale}px`,
            height: `${actualElementHeight * scale}px`,
            pointerEvents: 'none',
          }}
        />
      );
    }
    return imageDivs.reverse();
  }

  dragStartHandler(e) {
    e.preventDefault();
    return false;
  }

  render() {
    const { onTouchStart, onTouchMove, onTouchEnd } = this.props;
    return (
      <div
        id='im-carousel'
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
        onTouchStart={onTouchStart}
        onMouseDown={onTouchStart}
        onTouchMove={onTouchMove}
        onMouseMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onMouseUp={onTouchEnd}
        onMouseLeave={onTouchEnd}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            height: '90%',
          }}
        >
          {this.getImages()}
        </div>
      </div>
    );
  }
}

export default Carousel;
